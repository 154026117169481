import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import Rebase from "re-base";

const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY_PROD,
  authDomain: "klingentrail-status.firebaseapp.com",
  databaseURL: "https://klingentrail-status.firebaseio.com",
  projectId: "klingentrail-status",
  storageBucket: "",
  messagingSenderId: "329318735855",
};

const devConfig = {
  apiKey: process.env.REACT_APP_API_KEY_DEV,
  authDomain: "klingentrail-status-dev.firebaseapp.com",
  databaseURL: "https://klingentrail-status-dev.firebaseio.com",
  projectId: "klingentrail-status-dev",
  storageBucket: "klingentrail-status-dev.appspot.com",
  messagingSenderId: "988020969486",
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

const firebaseApp = firebase.initializeApp(config);

const auth = firebaseApp.auth();
const db = Rebase.createClass(firebaseApp.database());

export { auth, db };
