import React from "react";
import withAuthorization from "./withAuthorization";
import StatusForm from "./StatusForm";

class Dashboard extends React.Component {
  render() {
    return (
      <div className="Dashboard">
        <h2>Trailstatus ändern</h2>
        <StatusForm />
      </div>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(Dashboard);
