import React from "react";
import AuthUserContext from "./AuthUserContext";
import SignOutButton from "./SignOut";

const Footer = () => (
  <AuthUserContext.Consumer>
    {authUser => (authUser ? <SignOutButton /> : null)}
  </AuthUserContext.Consumer>
);

export default Footer;
