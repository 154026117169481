import React from "react";
import withAuthorization from "./withAuthorization";

const LandingPage = () => (
  <div>
    <h2>Bitte einloggen</h2>
  </div>
);

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(LandingPage);
