import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { auth } from "../firebase";
import * as routes from "../constants/routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Input from "./Input";
import Button from "./Button";

const SignInPage = ({ history }) => (
  <div>
    <h1>Login</h1>
    <SignInForm history={history} />
  </div>
);

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  email: "",
  password: ""
};

class SignInForm extends Component {
  state = { ...INITIAL_STATE };

  onSubmit = event => {
    const { email, password } = this.state;
    const { history } = this.props;
    toast.dismiss();

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        history.push(routes.DASHBOARD);
      })
      .catch(error => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: false
        });
      });

    event.preventDefault();
  };

  render() {
    const { email, password } = this.state;
    const isInvalid = password === "" || email === "";

    return (
      <>
        <ToastContainer />
        <form onSubmit={this.onSubmit}>
          <Input
            value={email}
            onChange={event =>
              this.setState(byPropKey("email", event.target.value))
            }
            type="text"
            placeholder="E-Mail-Adresse"
          />
          <Input
            value={password}
            onChange={event =>
              this.setState(byPropKey("password", event.target.value))
            }
            type="password"
            placeholder="Passwort"
          />
          <Button disabled={isInvalid} type="submit">
            Einloggen
          </Button>
        </form>
      </>
    );
  }
}

export default withRouter(SignInPage);

export { SignInForm };
