import styled from "styled-components";

const Button = styled.button`
  background: ${props =>
    props.logout ? "rgb(var(--error))" : "rgb(var(--primary))"};
  border: 0;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin: 0 0 15px 0;
  padding: 15px;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export default Button;
