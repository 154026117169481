import React from "react";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import logo from "../images/KlingenTrail-Logo.svg";
import * as routes from "../constants/routes";
const appName = "Klingentrail Status";

const Header = () => (
  <div>
    <Navigation />
    <Link to={routes.LANDING} className="logo">
      <img src={logo} alt={`${appName} Logo`} width={150} />
    </Link>
  </div>
);

export default Header;
