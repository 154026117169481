import styled from "styled-components";

const Input = styled.input`
  border: 1px solid rgb(var(--primary));
  border-color: rgb(var(--primary)); /* repeat for Safari render bug */
  box-shadow: inset 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  color: var(--input);
  display: block;
  margin: 0 0 10px 0;
  padding: 10px 10px;
  width: 100%;
`;

export default Input;
