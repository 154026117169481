import React from "react";
import { firebase } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Select from "./Select";
import Button from "./Button";
import TextareaAutosize from "./Textarea";

class StatusForm extends React.Component {
  state = {
    trail: {}
  };

  // Create references to grab values from DOM elements
  statusRef = React.createRef();
  messageRef = React.createRef();

  componentDidMount() {
    // Fetch state from Firebase
    this.refStatus = firebase.db
      .fetch("trail", {
        context: this
      })
      .then(data => {
        this.setState({
          trail: data
        });
      });
  }

  componentWillUnmount() {
    // Remove Firebase listener when component is unmounted
    firebase.db.removeBinding(this.refStatus);
  }

  // Update the state during input
  handleChange = event => {
    const updatedTrail = {
      ...this.state.trail,
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.setState({
      trail: updatedTrail
    });
  };

  handleKeyPress = event => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.preventDefault();
      event.currentTarget.blur();
      this.handleSubmit(event);
    }
  };

  // Submit form
  handleSubmit = event => {
    event.preventDefault();

    firebase.db
      .post("trail", {
        data: this.state.trail,
        context: this
      })
      .then(() => {
        toast.success("Status saved!", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2000
        });
      })
      .catch(err => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: false
        });
      });
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <form onSubmit={this.handleSubmit}>
          <Select
            ref={this.statusRef}
            value={this.state.trail.status}
            name="status"
            onChange={this.handleChange}
          >
            <option value="open">Trail offen</option>
            <option value="closed">Trail geschlossen</option>
          </Select>
          <TextareaAutosize
            ref={this.messageRef}
            rows={1}
            value={this.state.trail.message}
            placeholder="Nachricht"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            name="message"
            spellCheck="false"
          />
          <small>
            Nachricht leer lassen, um die jeweiligen Standardtexte auszugeben.
          </small>
          <br />
          <Button type="submit">Speichern</Button>
        </form>
      </div>
    );
  }
}

export default StatusForm;
