import styled from "styled-components";

const Select = styled.select`
  border: 1px solid rgb(var(--primary));
  border-color: rgb(var(--primary)); /* repeat for Safari render bug */
  border-radius: 0;
  color: var(--input);
  display: block;
  padding: 15px;
  width: 100%;
  margin: 0 0 10px 0;
`;

export default Select;
