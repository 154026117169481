import styled from "styled-components";

const Box = styled.div`
  background: white;
  border: 1px solid rgba(var(--primary), 0.25);
  border-color: rgba(var(--primary), 0.25); /* repeat for Safari render bug */
  border-radius: 2px;
  margin: 0 0 20px 0;
  padding: 25px 20px 10px;
`;

export default Box;
