import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import styled from "styled-components";

import * as routes from "../constants/routes";
import withAuthentication from "./withAuthentication";

import Header from "./Header";
import Box from "./Box";
import Footer from "./Footer";
import LandingPage from "./Landing";
// import SignUpPage from "./SignUp";
import SignInPage from "./SignIn";
// import PasswordForgetPage from "./PasswordForget";
import Dashboard from "./Dashboard";
// import AccountPage from "./Account";
// import NotFound from "./NotFound";

import "./App.css";

const AppWrapper = styled.div`
  margin: 0 auto;
  max-width: 400px;
  padding: 20px 20px;
  text-align: center;
`;

const App = () => (
  <Router>
    <AppWrapper>
      <Header />
      <Box>
        <Route exact path={routes.LANDING} component={LandingPage} />
        {/* <Route exact path={routes.SIGN_UP} component={SignUpPage} /> */}
        <Route exact path={routes.SIGN_IN} component={SignInPage} />
        {/* <Route
              exact
              path={routes.PASSWORD_FORGET}
              component={PasswordForgetPage}
            /> */}
        <Route exact path={routes.DASHBOARD} component={Dashboard} />
        {/* <Route component={NotFound} /> */}
        {/* <Route exact path={routes.ACCOUNT} component={AccountPage} /> */}
      </Box>
      <Footer />
    </AppWrapper>
  </Router>
);

export default withAuthentication(App);
